import { combineReducers } from "redux";
import auth from "./auth.slice";
import entities from "./entities.slice";
import admins from "./admins.slice";
import roles from "./roles.slice";
import permissions from "./permissions.slice";
import news from "./news.slice";
import partners from "./partners.slice";
import governments from "./governments.slice";
import villages from "./villages.slice";
import beneficiaries from "./beneficiaries.slice";
import committees from "./committees.slice";
import membershipCats from "./membershipCats.slice";
import memberships from "./memberships.slice";
import volunteers from "./volunteers.slice";
import directors from "./directors.slice";
import jobs from "./jobs.slice";
import messages from "./messages.slice";
import initiatives from "./initiatives.slice";
import projects from "./projects.slice";
import reports from "./reports.slice";
import govFiles from "./govFiles.slice";
import jobApps from "./jobApps.slice";
import commMembers from "./commMembers.slice";
import settings from "./settings.slice";
import goals from "./goals.slice";
import principles from "./principles.slice";
import govFileTypes from "./govFileTypes.slice";

const rootReducer = combineReducers({
	auth,
	entities,
	admins,
	roles,
	permissions,
	news,
	partners,
	governments,
	villages,
	beneficiaries,
	committees,
	membershipCats,
	memberships,
	volunteers,
	directors,
	jobs,
	messages,
	initiatives,
	projects,
	reports,
	govFiles,
	jobApps,
	commMembers,
	settings,
	goals,
	principles,
	govFileTypes,
});

export default rootReducer;
