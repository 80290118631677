import { createIcon } from "@chakra-ui/react";

export const PlusIcon = createIcon({
	displayName: "PlusIcon",
	viewBox: "0 0 32 32",
	path: (
		<g fill="currentColor">
			<path d="M9.75 16.001c0 0.69 0.56 1.25 1.25 1.25v0h3.749v3.749c0 0.69 0.56 1.25 1.25 1.25s1.25-0.56 1.25-1.25v-3.749h3.749c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25h-3.749v-3.749c0-0.69-0.56-1.25-1.25-1.25s-1.25 0.56-1.25 1.25v0 3.749h-3.749c-0.69 0-1.25 0.56-1.25 1.25v0z"></path>
			<path d="M10.025 3.862l1.617-0.669c0.457-0.192 0.773-0.637 0.773-1.155 0-0.69-0.56-1.25-1.25-1.25-0.172 0-0.336 0.035-0.485 0.098l0.008-0.003-1.617 0.669c-0.457 0.192-0.773 0.637-0.773 1.155 0 0.69 0.56 1.25 1.25 1.25 0.172 0 0.336-0.035 0.485-0.098l-0.008 0.003z"></path>
			<path d="M16.001 0c-0.69 0-1.25 0.56-1.25 1.25s0.56 1.25 1.25 1.25v0c7.456 0 13.5 6.044 13.5 13.5s-6.044 13.5-13.5 13.5c-7.456 0-13.5-6.044-13.5-13.5v0c0-0.69-0.56-1.25-1.25-1.25s-1.25 0.56-1.25 1.25v0c0 0 0 0.001 0 0.002 0 8.837 7.164 16.001 16.001 16.001s16.001-7.164 16.001-16.001c0-8.837-7.164-16.001-16.001-16.001-0.001 0-0.001 0-0.002 0h0z"></path>
			<path d="M5.836 7.073l1.237-1.237c0.226-0.226 0.366-0.539 0.366-0.884 0-0.691-0.56-1.25-1.25-1.25-0.345 0-0.658 0.14-0.884 0.366l-1.237 1.237c-0.226 0.226-0.366 0.539-0.366 0.884 0 0.691 0.56 1.25 1.25 1.25 0.345 0 0.658-0.14 0.884-0.366v0z"></path>
			<path d="M1.558 12.318c0.141 0.060 0.306 0.095 0.479 0.095 0.518 0 0.962-0.315 1.151-0.763l0.003-0.008 0.669-1.617c0.060-0.141 0.095-0.305 0.095-0.477 0-0.69-0.56-1.25-1.25-1.25-0.518 0-0.963 0.315-1.152 0.765l-0.003 0.008-0.669 1.617c-0.059 0.14-0.094 0.304-0.094 0.475 0 0.518 0.315 0.962 0.763 1.151l0.008 0.003z"></path>
		</g>
	),
});

export const UploadIcon = createIcon({
	displayName: "UploadIcon",
	viewBox: "0 0 39 32",
	path: (
		<g stroke="currentColor">
			<path
				strokeLinejoin="miter"
				strokeLinecap="butt"
				strokeMiterlimit="4"
				strokeWidth="0.654"
				d="M27.514 24.535c-1.679 0-3.375-0.018-5.054 0-0.344-0.012-0.62-0.288-0.632-0.631l-0-0.001c0.018-1.046 0.213-0.632 1.264-0.632 2.991 0 5.853 0.009 8.846 0 3.181-0.176 5.694-2.799 5.694-6.008 0-0.109-0.003-0.217-0.009-0.325l0.001 0.015c-0.034-2.372-1.602-4.368-3.755-5.044l-0.038-0.010c-0.315-0.074-0.558-0.317-0.631-0.626l-0.001-0.006c-0.434-2.919-2.922-5.133-5.928-5.133-0.361 0-0.714 0.032-1.058 0.093l0.036-0.005c-1.105 0.162-2.269-0.042-3.159 0.632-0.269 0.197-0.523 0.269-0.632 0-1.299-3.007-4.238-5.073-7.66-5.073-0.195 0-0.388 0.007-0.58 0.020l0.026-0.001c-4.273 0.124-7.747 3.381-8.211 7.548l-0.003 0.038c-0.049 0.285-0.077 0.613-0.077 0.948s0.028 0.663 0.082 0.982l-0.005-0.034c0.054 0.297 0.251 0.551 0 0.632-2.488 0.775-4.287 2.999-4.419 5.662l-0.001 0.015c0.007 3.138 2.548 5.68 5.685 5.687h0.001c3.207 0.018 6.271 0.018 9.478 0 0.023-0.003 0.049-0.005 0.076-0.005 0.31 0 0.561 0.251 0.561 0.561 0 0.027-0.002 0.053-0.005 0.079l0-0.003c-0.027 0.925 0.302 0.632-0.632 0.632h-8.846c-0.084 0.003-0.182 0.005-0.28 0.005-4.038 0-7.312-3.274-7.312-7.312 0-0.099 0.002-0.197 0.006-0.294l-0 0.014c0.075-2.525 1.592-4.678 3.753-5.669l0.040-0.017c0.262-0.131 0.632-0.327 0.632-0.632-0.006-0.127-0.009-0.275-0.009-0.425 0-4.858 3.531-8.891 8.167-9.673l0.058-0.008c0.508-0.091 1.092-0.143 1.688-0.143 3.511 0 6.601 1.8 8.398 4.527l0.023 0.038c0.242 0.377 0.209 0.17 0.632 0 0.819-0.298 1.765-0.471 2.75-0.471 3.815 0 7.026 2.585 7.977 6.099l0.013 0.058c0.072 0.279 0.362-0.131 0.632 0 2.322 1.3 3.866 3.744 3.866 6.548 0 0.366-0.026 0.726-0.077 1.078l0.005-0.040c-0.279 3.163-2.61 5.709-5.641 6.31l-0.045 0.007c-0.284 0.032-0.614 0.051-0.948 0.051s-0.663-0.018-0.988-0.054l0.040 0.004c-1.266-0.009-2.536-0.009-3.794-0.009z"
			></path>
			<path
				strokeLinejoin="miter"
				strokeLinecap="butt"
				strokeMiterlimit="4"
				strokeWidth="0.654"
				d="M19.301 15.697c-1.5 1.509-3.040 3.008-4.422 4.422-0.279 0.279-0.344 0.314-0.632 0-0.71-0.764-0.727-0.536 0-1.264 1.806-1.806 3.249-3.863 5.054-5.686 0.225-0.225 0.362-0.269 0.632 0 1.962 1.986 4.341 4.36 6.318 6.318 0.216 0.216 0.251-0.207 0 0-0.28 0.117-0.606 0.185-0.948 0.185s-0.667-0.068-0.964-0.191l0.017 0.006c-1.159-1.15-2.009-2.009-3.159-3.159l-0.632-0.632c-0.131 0.17 0 0.479 0 0.632 0 4.905-0.018 10.259 0 15.164 0 0.404-0.218 0.018-0.632 0-0.934-0.027-0.632 0.293-0.632-0.632v-14.532c-0.018-0.12 0-0.327 0-0.632z"
			></path>
		</g>
	),
});

export const PdfIcon = createIcon({
	displayName: "PdfIcon",
	viewBox: "0 0 28 32",
	path: (
		<g fill="currentColor">
			<path
				opacity="0.11"
				d="M3.556 0h21.333c1.964 0 3.556 1.592 3.556 3.556v24.889c0 1.964-1.592 3.556-3.556 3.556h-21.333c-1.964 0-3.556-1.592-3.556-3.556v-24.889c0-1.964 1.592-3.556 3.556-3.556z"
			/>
			<path d="M8.683 14.003h-1.37c0 0 0 0 0 0-0.358 0-0.648 0.29-0.648 0.648 0 0.001 0 0.002 0 0.002v-0l0.009 4.921c0 0.358 0.29 0.648 0.648 0.648s0.648-0.29 0.648-0.648v0-1.493h0.711c0.011 0 0.025 0 0.038 0 1.125 0 2.037-0.912 2.037-2.037s-0.912-2.037-2.037-2.037c-0.013 0-0.027 0-0.040 0l0.002-0zM8.683 16.782h-0.717c0-0.276 0-0.572 0-0.744 0-0.146 0-0.453 0-0.742h0.721c0.007-0 0.015-0 0.023-0 0.41 0 0.742 0.332 0.742 0.742s-0.332 0.742-0.742 0.742c-0.008 0-0.016-0-0.024-0l0.001 0z"></path>
			<path d="M14.194 14.003h-1.351c0 0 0 0 0 0-0.358 0-0.648 0.29-0.648 0.648 0 0.001 0 0.001 0 0.002v-0s0.010 4.764 0.010 4.778c0.001 0.357 0.291 0.646 0.648 0.646 0 0 0 0 0 0v0c0.041 0 1.006-0.004 1.392-0.011 1.463-0.026 2.526-1.298 2.526-3.026 0-1.816-1.035-3.036-2.576-3.036zM14.222 18.768c-0.168 0-0.457 0.005-0.726 0.007 0-0.563-0.006-2.893-0.006-3.478h0.704c1.189 0 1.28 1.333 1.28 1.741 0.001 0.853-0.386 1.717-1.252 1.73z"></path>
			<path d="M21.043 15.249c0.358 0 0.648-0.29 0.648-0.648s-0.29-0.648-0.648-0.648h-1.98c-0.358 0-0.648 0.29-0.648 0.648v0 4.887c0 0.358 0.29 0.648 0.648 0.648s0.648-0.29 0.648-0.648v-1.842h1.175c0.358 0 0.648-0.29 0.648-0.648s-0.29-0.648-0.648-0.648h-1.175v-1.101z"></path>
			<path d="M22.945 11.24h-0.474v-0.185c0-0.001 0-0.003 0-0.005 0-0.863-0.339-1.646-0.89-2.225l0.001 0.001-2.691-2.838c-0.592-0.622-1.426-1.009-2.35-1.011h-8.624c-1.073 0.001-1.943 0.87-1.944 1.943v4.319h-0.475c-1.072 0.002-1.941 0.87-1.943 1.943v7.774c0.002 1.072 0.871 1.94 1.943 1.941h0.474v2.247c0.001 1.073 0.871 1.942 1.943 1.943h12.61c1.073-0.001 1.943-0.87 1.944-1.943v-2.247h0.475c1.072-0.002 1.94-0.87 1.943-1.941v-7.774c-0.002-1.073-0.871-1.942-1.943-1.943h-0zM7.27 6.921c0-0.358 0.29-0.647 0.648-0.648h8.624c0.555 0.001 1.055 0.234 1.409 0.606l0.001 0.001 2.691 2.838c0.33 0.347 0.533 0.817 0.533 1.334 0 0.001 0 0.002 0 0.003v-0 0.185h-13.906zM21.175 25.145c-0 0.358-0.29 0.647-0.648 0.648h-12.609c-0.358-0-0.647-0.29-0.648-0.648v-2.247h13.905zM23.593 20.956c-0 0.358-0.29 0.647-0.648 0.648h-17.446c-0.358-0-0.647-0.29-0.648-0.648v-7.773c0-0.358 0.29-0.647 0.648-0.648h17.446c0.358 0 0.647 0.29 0.648 0.648v0z"></path>
		</g>
	),
});

export const SheetIcon = createIcon({
	displayName: "SheetIcon",
	viewBox: "0 0 32 32",
	path: (
		<g fill="currentColor">
			<path d="M23.508 6.421h5.684l-5.983-5.883v5.578c0.001 0.164 0.134 0.297 0.299 0.297 0 0 0 0 0 0v0z"></path>
			<path d="M23.502 8.295c-1.197-0.005-2.165-0.975-2.167-2.172v-6.123h-15.583c-1.932 0-3.498 1.566-3.498 3.498v0 25.004c0 1.932 1.566 3.498 3.498 3.498v0h20.496c1.932 0 3.498-1.566 3.498-3.498v0-20.206zM21.365 24.25h-12.075c-0.012 0-0.025 0.001-0.039 0.001-0.518 0-0.938-0.42-0.938-0.938s0.42-0.938 0.938-0.938c0.014 0 0.027 0 0.041 0.001l-0.002-0h12.075c0.012-0 0.025-0.001 0.039-0.001 0.518 0 0.938 0.42 0.938 0.938s-0.42 0.938-0.938 0.938c-0.014 0-0.027-0-0.041-0.001l0.002 0zM8.352 19.558c0-0.518 0.42-0.938 0.938-0.938v0h10.774c0.501 0.021 0.9 0.433 0.9 0.937s-0.398 0.916-0.898 0.937l-0.002 0h-10.774c0 0 0 0 0 0-0.518 0-0.937-0.419-0.938-0.936v-0zM22.503 16.745h-13.213c-0.012 0-0.025 0.001-0.039 0.001-0.518 0-0.938-0.42-0.938-0.938s0.42-0.938 0.938-0.938c0.014 0 0.027 0 0.041 0.001l-0.002-0h13.215c0.012-0.001 0.025-0.001 0.039-0.001 0.518 0 0.938 0.42 0.938 0.938s-0.42 0.938-0.938 0.938c-0.014 0-0.027-0-0.041-0.001l0.002 0z"></path>
		</g>
	),
});

export const SquaresIcon = createIcon({
	displayName: "SquaresIcon",
	viewBox: "0 0 32 32",
	path: (
		<g fill="currentColor">
			<path d="M17.783 32v-17.783h14.217v17.783zM0 32v-10.667h14.217v10.667zM0 17.783v-17.783h14.217v17.783zM17.783 10.667v-10.667h14.217v10.667z"></path>
		</g>
	),
});
